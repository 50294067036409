import DashboardLayout from "../../views/Layout/DashboardLayout.vue";

export default {
    path: "/",
    name: "Dashboard",
    component: DashboardLayout,
    children: [
        {
            path: "/dashboard",
            name: "Dashboard",
            component: () => import(/* webpackChunkName: "account" */ "@/views/Dashboard/Dashboard.vue"),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: "/dashboard/overview",
                    name: "Dashboard Overview",
                    component: () => import(/* webpackChunkName: "profile" */ "@/views/Dashboard/pages/Overview.vue"),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: "/dashboard/upcoming",
                    name: "Dashboard Upcoming",
                    component: () => import(/* webpackChunkName: "notifications" */ "@/views/Dashboard/pages/Upcoming.vue"),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: "/dashboard/overdue",
                    name: "Dashboard Overdue",
                    component: () => import(/* webpackChunkName: "users" */ "@/views/Dashboard/pages/Overdue.vue"),
                    meta: {
                        requiresAuth: true
                    }
                }, 
                {
                    path: "/dashboard/transferred",
                    name: "Dashboard Transferred",
                    component: () => import(/* webpackChunkName: "users" */ "@/views/Dashboard/pages/Transferred.vue"),
                    meta: {
                        requiresAuth: true
                    }
                }
            ]
        },
        {
            path: "analytics/:id?",
            name: "Analytics",
            component: () => import(/* webpackChunkName: "members-list" */ "@/views/FormAnalytics/Analytics.vue"),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "form-manager/:formCollectionId?",
            name: "FormManager",
            component: () => import(/* webpackChunkName: "members-list" */ "@/views/FormManager/FormList/FormList.vue"),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "form-editor",
            name: "FormEditor",
            component: () => import(/* webpackChunkName: "members-list" */ "@/views/FormManager/FormEditor/FormEditor.vue"),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: "/edit-form/:id?",
                    name: "Edit",
                    component: () => import(/* webpackChunkName: "profile" */ "@/views/FormManager/FormEditor/FormBuilder.vue"),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: "/preview-form/:id?",
                    name: "preview",
                    component: () => import(/* webpackChunkName: "notifications" */ "@/views/FormManager/FormEditor/FormPreview.vue"),
                    meta: {
                        requiresAuth: true
                    }
                }
            ]
        },
        {
            path: "my-forms",
            name: "MyForms",
            component: () => import(/* webpackChunkName: "members-list" */ "@/views/MyForms/FormList/FormList.vue"),
            meta: {
                requiresAuth: true,
                mobileAllowed: true
            }
        },
        {
            path: "edit-submission/:id?",
            name: "EditSubmission",
            component: () => import(/* webpackChunkName: "members-list" */ "@/views/MyForms/FormEditor/FormSubmission.vue"),
            props: true,
            meta: {
                requiresAuth: true,
                mobileAllowed: true
            }
        },
        {
            path: "qr-code",
            name: "QrCode",
            component: () => import(/* webpackChunkName: "members-list" */ "@/views/QrCode/QrCode.vue"),
            meta: {
                requiresAuth: true,
                mobileAllowed: true
            }
        }
    ]
};
