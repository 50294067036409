// import store from '@/store'
// import router from '@/router'
// import { apiClient, makeAuthPlugin as makeApiAuthPlugin } from '@/store/api'
import { makeAuthPlugin as makeApiAuthPlugin } from '@/store/api'

const apiAuth = makeApiAuthPlugin({
    userService: 'users', namespace: 'apiAuth', getters: {
        accessToken(state) {
            if (!state.user) return null;
            const { accessToken } = state;
            return accessToken || null;
        }
    }
})

// listen for authentication events
/* apiClient.on('authentication', async (payload) => {
  if (payload.event === 'session-expired' || payload.event === 'not-authenticated') {
    await store.dispatch('apiAuth/logout')
    router.push({ name: 'auth-signin', params: { reason: 'Session expired or not authenticated, please login' } })
  }
}) */

export { apiAuth }
